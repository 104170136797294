body {
  margin: 0;
  font-family:'EduDiatype-Mono-Regular',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Desktop */
@media only screen and (min-width:769px){
  body {
    /* padding: 26px; */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'EduDiatype-Mono-Regular';
  src: local('MyFont'), url(./fonts/EduDiatypeMono-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'EduDiatype-Mono-Medium';
  src: local('MyFont'), url(./fonts/EduDiatypeMono-Medium.woff2) format('woff2');
}


@font-face {
  font-family: 'EduDiatype-Bold';
  src: local('MyFont'), url(./fonts/EduDiatype-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'EduDiatype-Bolditalic';
  src: local('MyFont'), url(./fonts/EduDiatype-BoldItalic.woff2) format('woff2');
}

a:link    { color: inherit; text-decoration: none; }
a:visited { color: inherit; text-decoration: none; }
a:hover   { color: inherit; text-decoration: underline; }
a:active  { color: inherit; text-decoration: underline; }


.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

sup{
  vertical-align: top;
    font-size: smaller;
    line-height: 1;
    color:#9D9D9D
}